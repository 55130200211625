@import url(https://fonts.googleapis.com/css2?family=Courgette&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Signika+Negative:wght@500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-size: 17px;
}

html {
  scroll-behavior: smooth;
}

.vh-35 {
  min-height: 35vh;
}

.text-align-center {
  text-align: center;
}

.curved-top {
  border-top-left-radius: 30%;
  border-top-right-radius: 30%;
  -webkit-border-top-left-radius: 30%;
  -webkit-border-top-right-radius: 30%;
  -moz-border-top-left-radius: 30%;
  -moz-border-top-right-radius: 30%;
}

.curved-bottom {
  border-bottom-right-radius: 30%;
  border-bottom-left-radius: 30%;
  -webkit-border-bottom-right-radius: 30%;
  -webkit-border-bottom-left-radius: 30%;
  -moz-border-bottom-right-radius: 30%;
  -moz-border-bottom-left-radius: 30%;
}

.no-margin {
  margin: 0;
}

.m-auto {
  margin: 0 auto;
}

.w-30-70 {
  width: 30vw;
}

.width-75-100 {
  width: 75%;
}

.divider {
  border: 1px solid black;
  margin: 0 auto 3em auto;
  width: 20%;
}

.divider-light {
  border: 1px solid rgb(197, 197, 197);
  margin: 3em auto 1em auto;
  width: 20%;
}

.link-scroll {
  text-decoration: none;
}

.width {
  width: 30px;
}

.social-media,
.skills,
.introduction,
.flex {
  display: flex;
  align-items: center;
}

.legend {
  transition-duration: 200ms;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: fixed;
  left: 92vw;
  top: 45vh;
  border: 1px solid #0d6efd;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0);
}

.legend-nav {
  padding: 0.3em;
}

.legend-nav:hover {
  transition-duration: 200ms;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
  color: #0d6efd;
}

/*--------------*/
/* PROJECTS.JSX */
/*--------------*/
.main-title {
  font-family: "Signika Negative";
}

.projects {
  padding: 20px;
  margin-top: 2em;
  width: 85%;
}

.container-project:not(:last-child) {
  padding-bottom: 2em;
}

.container-project {
  display: flex;
}

.intro {
  width: 40%;
}

.about-project {
  width: 60%;
}

.about-container {
  width: 75%;
}

.project-img {
  width: 20em;
  max-width: 100%;
  margin: 0 auto;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
  transition: 300ms ease-in-out;
}

.img-wrapper {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.project-img:hover {
  transform: scale(1.1);
}

.br-50 {
  border-radius: 50%;
  -webkit-border-radius: 50%;
}

.about-project {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
}

/*------------------*/
/* INTRODUCTION.JSX */
/*------------------*/
.introduction {
  flex-direction: column;
  margin-top: 3em;
}

.img-container {
  width: 200px;
  height: 200px;
}

.intro-text {
  width: 40%;
  margin-left: 2rem;
}

/*--------------*/
/* PROFILES.JSX */
/*--------------*/
.social-media {
  flex-direction: column;
  margin-top: 1em;
}

.logo,
.px64 {
  width: 64px;
}

.clickable:hover {
  cursor: pointer;
}

.wrapper {
  margin: 0 auto;
  flex-wrap: wrap;
}

.slide-container {
  width: 90%;
  max-width: 700px;
  margin: 0 auto;
}

.img-photoshop {
  display: block;
  margin: 0 auto;
  max-width: 500px;
  width: 100%;
  padding: 10px;
}

.before-auto {
  margin: 0 10px 0 auto;
}

.after-auto {
  margin: 0 auto 0 10px;
}

/*-----------*/
/* ABOUT.JSX */
/*-----------*/
.about-image {
  width: calc(100% / 3);
  max-width: 800px;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
}

.about-text {
  width: 60%;
}

/*------------*/
/* FOOTER.JSX */
/*------------*/
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 20vh;
}

/*----------------*/
/* MOBILE SUPPORT */
/*----------------*/
.phone-header {
  height: 50vw;
  font-size: 1em;
}

.mobile-add {
  display: none;
}

.img-phone-support {
  width: 100%;
}

@media screen and (max-width: 1300px) {
  .legend {
    transition-duration: 200ms;
    left: 88vw;
  }
}

@media screen and (max-width: 1200px) {
  .projects {
    padding: 10px;
    margin-top: 1em;
    width: 95%;
  }

  .container-project {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .about-project,
  .about-project p {
    width: 100%;
    text-align: center;
  }

  .about-description {
    font-weight: bold;
  }

  .used-technologies-container {
    margin: auto;
  }

  .intro {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .mobile-add {
    display: block;
  }

  .mobile-remove {
    display: none;
  }

  .btn-container {
    display: flex;
  }

  .width-75-100 {
    width: 100%;
  }

  .w-30-70 {
    width: 70%;
  }
}

@media screen and (max-width: 1000px) {
  .project-img:hover {
    transform: scale(1);
  }
}

@media screen and (max-width: 800px) {
  .legend {
    transition-duration: 200ms;
    left: 83vw;
  }
}

@media screen and (max-width: 600px) {
  .about-text {
    width: 90vw;
  }
}

@media screen and (max-width: 500px) {
  .legend {
    transition-duration: 200ms;
    left: 80vw;
    top: 92vh;
  }

  .skills {
    overflow: hidden;
  }

  .curved-top {
    border-top-left-radius: 20%;
    border-top-right-radius: 20%;
    -webkit-border-top-left-radius: 20%;
    -webkit-border-top-right-radius: 20%;
    -moz-border-top-left-radius: 20%;
    -moz-border-top-right-radius: 20%;
  }

  .curved-bottom {
    border-bottom-right-radius: 20%;
    border-bottom-left-radius: 20%;
    -webkit-border-bottom-right-radius: 20%;
    -webkit-border-bottom-left-radius: 20%;
    -moz-border-bottom-right-radius: 20%;
    -moz-border-bottom-left-radius: 20%;
  }

  .about-image {
    width: 100%;
  }

  .phoneSupport {
    width: 36px;
  }
}

@media screen and (max-width: 450px) {
  .px-48 {
    max-width: 48px;
  }
}
